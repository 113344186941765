import { isNil } from "lodash";
import { IOrganization } from "../entities/Organization";

export type ShopifyGidNamespace =
  | "AppSubscription"
  | "Customer"
  | "MailingAddress"
  | "Collection"
  | "InventoryItem"
  | "LineItem"
  | "Product"
  | "ProductVariant"
  | "Order"
  | "OrderTransaction"
  | "FulfillmentOrder"
  | "SubscriptionLine"
  | "SubscriptionContract"
  | "SubscriptionDraft"
  | "SellingPlan"
  | "SellingPlanGroup"
  | "CustomerPaymentMethod"
  | "BillingAttempt"
  | "CustomerVisit"
  | "MarketingEvent"
  | "DeliveryProfile"
  | "Location";

export function hasShopifyGid(id: string): boolean {
  return id.startsWith("gid://shopify/");
}

export function getShopifyGidPrefix(namespace: ShopifyGidNamespace): string {
  return `gid://shopify/${namespace}/`;
}

/**
 * Some Shopify Ids have a query string attached to them, so we strip it out so
 * they're uniform across all object types.
 */
export function stripQuery(s: string): string {
  return s.split("?")[0];
}

/**
 * Convert a Shopify Rest API ID to a Shopify GraphQL ID, or pass it through if
 * it's already a GraphQL ID. Also strips out any query string from the ID.
 *
 * @see {@link stripQuery}
 */
export function ensureShopifyGid(namespace: ShopifyGidNamespace, id: number | string): string {
  const sid = `${id}`;
  const prefix = `gid://shopify/${namespace}`;
  const idWithQueryRemoved = stripQuery(sid);

  if (sid.startsWith(prefix)) {
    return idWithQueryRemoved;
  }

  return `${prefix}/${idWithQueryRemoved}`;
}

export function scopeShopifyGidToOrg(
  namespace: ShopifyGidNamespace,
  organization: IOrganization,
  id: number | string
): string {
  const orgScopingPrefix = `${organization.id}_`;

  // attempt to prevent double scoping
  if (typeof id === "string" && id.startsWith(orgScopingPrefix)) {
    return id;
  }

  return `${orgScopingPrefix}${ensureShopifyGid(namespace, id)}`;
}

export function orgIdFromUniqueShopifyId(uniqueShopifyId: string): string {
  const parts = uniqueShopifyId.split("_");
  if (parts.length !== 2) {
    throw new Error(`Provided string is not a unique shopify id`);
  }
  return parts[0];
}

// Safe function that can extract any type of shopify ID, including payment methods
export function extractShopifyId(gid: string | number | undefined): string | undefined {
  if (!gid) {
    return undefined;
  }
  return typeof gid === "number" ? gid.toString() : gid.split("/").slice(-1)[0];
}

// needed for some REST APIs
// WARNING: do not use this for payment method's IDs, because they contain non-numeric characters
export function shopifyGidToNumber(gid: string | number): number {
  return typeof gid === "number" ? gid : Number(gid.split("/").slice(-1)[0]);
}

// for displaying in UI
// WARNING: do not use this for payment method's IDs, because they contain non-numeric characters
export function viewShopifyId(gid: string | number | undefined): string {
  return `${gid ? shopifyGidToNumber(gid.toString()) : "--"}`;
}

export function ensureOrgResourceOrThrow<T extends { __uniqueShopifyId__?: string }>(
  organization: IOrganization | string,
  resource: T | string | undefined
): void {
  if ((typeof resource !== "string" && isNil(resource?.__uniqueShopifyId__)) || isNil(resource)) {
    throw new Error("Resource does not have a unique shopify id");
  }

  const resourceId = typeof resource === "string" ? resource : resource.__uniqueShopifyId__!;
  const orgId = typeof organization === "string" ? organization : organization.id;

  if (orgIdFromUniqueShopifyId(resourceId) !== orgId) {
    throw new Error(`Organization ${orgId} does not own resource ${resourceId}`);
  }
}

export function shopifyNumericIdFromGid(gid: string): string {
  const match = gid.match(/\d+$/);

  if (!match) {
    throw new Error(`Error parsing numeric id from Shopify gid: ${gid}`);
  }

  return match[0];
}
